import gql from 'graphql-tag';
import fragments from './fragment';

export const getOrganisationQuery = gql`
  query Organisation($id: ID!) {
    data: Organisation(id: $id) {
      ...FullOrganisation
    }
  }

  ${fragments}
`;

const getOrganisation = async ({ client, params }) => {
  try {
    const response = await client.query({
      query: getOrganisationQuery,
      variables: params,
      fetchPolicy: 'network-only'
    });
    return response.data;
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  }
};

export default getOrganisation;
