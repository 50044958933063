const withErrorShaping = fn => (...args) => {
  return fn(...args).catch(e => {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  });
};

export const runQuery = withErrorShaping(({ client, ...rest }) => {
  return client.query(rest);
});

export const runMutation = withErrorShaping(({ client, ...rest }) => {
  return client.mutate(rest);
});
