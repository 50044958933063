import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Amplify from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';
import IdleDetection from './IdleDetection';

const TEN_MINUTES = 10 * 60 * 1000;
IdleDetection.clearTokenIfNoRecentActivity(TEN_MINUTES);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT,
    authenticationFlowType: process.env.REACT_APP_AWS_AUTHENTICATION_FLOW,
  },
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
