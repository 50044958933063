import gql from 'graphql-tag';
import { runMutation } from 'util/graphqlHelper';

function randomId() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

const itemEquals = (a, b) => {
  return a.id === b.id && a.country === b.country && a.url === b.url;
};

// WARNING: Mutates array values!!
const collectChangedItems = ({ oldItems, newItems, removeArr, addArr }) => {
  oldItems.forEach(oldItem => {
    const newItem = newItems.find(newItem => newItem.id === oldItem.id);
    // If item is in old but not in new, add to remove array
    if (!newItem) {
      removeArr.push(oldItem.id);
      // If item is in old  and in new but is not the same, add to remove array and to add array
    } else if (!itemEquals(newItem, oldItem)) {
      addArr.push(newItem);
      removeArr.push(oldItem.id);
    }
    // Otherwise do nothing
  });
  newItems.forEach(newItem => {
    if (!newItem.id) {
      // Add an ID
      newItem.id = randomId();
    }
    const oldItem = oldItems.find(oldItem => newItem.id === oldItem.id);
    if (!oldItem) {
      // If item is not in old but in new add to add array
      addArr.push(newItem);
    }
  });
};

const updateStorefrontDefaultData = async ({ client, params = {} }) => {
  // What can change?
  //
  // ios or android default can be updated
  //
  // international item can be removed
  // international item can be added
  //
  // (international item being changed == remove+add)
  //

  const ios = {
    default: params.data.ios.default,
    removeInternational: [],
    addInternational: [],
  };
  const android = {
    default: params.data.android.default,
    removeInternational: [],
    addInternational: [],
  };

  // iOS Items.
  // Mutates removeArr + addArr
  collectChangedItems({
    oldItems: params.previousData.ios.international,
    newItems: params.data.ios.international,
    removeArr: ios.removeInternational,
    addArr: ios.addInternational,
  });

  // Android Items.
  // Mutates removeArr + addArr
  collectChangedItems({
    oldItems: params.previousData.android.international,
    newItems: params.data.android.international,
    removeArr: android.removeInternational,
    addArr: android.addInternational,
  });

  const input = { ios, android };

  return runMutation({
    client,
    variables: { input },
    mutation: gql`
      mutation UpdateStorefrontDefaults(
        $input: StorefrontDefaultsUpdateInput!
      ) {
        StorefrontDefaults: updateStorefrontDefaults(input: $input) {
          id
          ios {
            default
            international {
              id
              country
              url
            }
          }
          android {
            default
            international {
              id
              country
              url
            }
          }
        }
      }
    `,
  }).then(result => {
    return {
      data: result.data.StorefrontDefaults,
    };
  });
};

export default updateStorefrontDefaultData;
