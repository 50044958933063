export const roleMapping = [
  { id: 'SUPER', name: 'Super Admin' },
  { id: 'HEALTH_AUTHORITY', name: 'Health Authority' },
  { id: 'CLINICIAN', name: 'Clinician' },
];

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
}
