import gql from 'graphql-tag';
import fragments from './fragment';

export const allOrganisationsQuery = gql`
  query allOrganisations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: OrganisationFilter
  ) {
    items: allOrganisations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...FullOrganisation
    }
    total: _allOrganisationsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }

  ${fragments}
`;

const allOrganisations = async ({ client, params }) => {
  try {
    const response = await client.query({
      query: allOrganisationsQuery,
      variables: {
        filter: params.filter,
        sortField: params.sort && params.sort.field,
        sortOrder: params.sort && params.sort.order,
        page: params.pagination
          ? !params.pagination.page
            ? 0
            : params.pagination.page - 1
          : 0,
        perPage: params.pagination && params.pagination.perPage,
      },
      fetchPolicy: 'network-only',
    });

    return {
      data: response.data.items,
      total: response.data.total.count,
    };
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  }
};

export default allOrganisations;
