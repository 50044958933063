import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import IdleTimer from 'react-idle-timer';
import { userLogout } from 'react-admin';

const RECENT_APP_ACTIVITY = 'recent-application-activity';

class IdleDetection extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.lastActivityInWindow = Date.now();
    this.state = {
      expiryWarning: false,
      timeout: this.initialTimeout,
    };

    window.addEventListener('storage', this.storeUpdated);
  }

  static clearTokenIfNoRecentActivity(timeout) {
    if (process.env.REACT_APP__DEVELOPMENT__NO_TIMEOUT) return;

    const mostRecentActivity =
      window.localStorage.getItem(RECENT_APP_ACTIVITY) || 0;
    if (mostRecentActivity + timeout < Date.now()) {
      window.localStorage.clear();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.storeUpdated);
  }

  storeUpdated = event => {
    // If another window sets the recent window activity update the timeout
    if (
      event.key === RECENT_APP_ACTIVITY &&
      // Sanity check:
      event.newValue > this.lastActivityInWindow
    ) {
      this.reset(event.newValue);
    }
  };

  get initialTimeout() {
    return this.props.timeout - this.props.prewarning;
  }

  reset(startingTime = Date.now()) {
    const timeout = startingTime - Date.now() + this.initialTimeout;
    this.setState({
      timeout,
      expiryWarning: false,
    });
    this.idleTimer.reset();
  }

  showWarning() {
    this.setState({
      expiryWarning: true,
      timeout: this.props.prewarning,
    });
    this.idleTimer.reset();
  }

  render() {
    if (process.env.REACT_APP__DEVELOPMENT__NO_TIMEOUT) return null;
    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={this.state.timeout}
          onIdle={e => this.handleOnIdle(e)}
          onAction={e => this.handleOnAction(e)}
          throttle={1000}
          capture={false}
          passive={true}
        />
        <Dialog
          open={this.state.expiryWarning}
          aria-labelledby="auto-logout-title"
        >
          <DialogTitle id="auto-logout-title">Automatic Logout</DialogTitle>
          <DialogContent>Your account will be logged out shortly</DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  set lastActivityForUser(val) {
    window.localStorage.setItem(RECENT_APP_ACTIVITY, val);
  }

  handleOnAction() {
    if (!this.idleTimer) {
      return;
    }
    this.lastActivityForUser = this.idleTimer.getLastActiveTime();
    this.lastActivityInWindow = this.idleTimer.getLastActiveTime();
    if (this.state.expiryWarning) {
      this.reset();
    }
  }

  handleOnIdle() {
    if (this.state.expiryWarning) {
      this.props.userLogout();
      this.reset();
    } else {
      this.showWarning();
    }
  }
}

IdleDetection.propTypes = {
  timeout: PropTypes.number,
  prewarning: PropTypes.number,
  userLogout: PropTypes.func,
};

IdleDetection.defaultProps = {
  timeout: 10 * 60 * 1000,
  prewarning: 5 * 60 * 1000,
};

const mapStateToProps = state => ({});
const actions = { userLogout };

export default connect(mapStateToProps, actions)(IdleDetection);
