import React from 'react';
import { change } from 'redux-form';

class FileMetaData extends React.Component {
  onLoadMetadata = ev => {
    const video = ev.target;

    const videoRegex = /video\//;
    const audioRegex = /audio\//;

    const { dispatch } = this.props.meta;
    const { rawFile } = this.props.record;

    if (video.duration > 1 && dispatch) {
      dispatch(
        change(
          'record-form',
          'mediaDurationSeconds',
          Math.round(video.duration)
        )
      );
    }

    if (rawFile && rawFile.type) {
      const fileType = videoRegex.test(rawFile.type)
        ? 'VIDEO'
        : audioRegex.test(rawFile.type)
        ? 'AUDIO'
        : null;
      dispatch(change('record-form', 'mediaType', fileType));
    }
  };

  get videoSrc() {
    return (
      this.props.record &&
      this.props.record.rawFile &&
      this.props.record.rawFile.preview
    );
  }

  render() {
    return this.videoSrc ? (
      <video
        muted
        hidden
        preload="metadata"
        onLoadedMetadata={this.onLoadMetadata}
        src={this.videoSrc}
      />
    ) : null;
  }
}

export default FileMetaData;
