import gql from 'graphql-tag';

export const RegenerateApiAccessKeyMutation = gql`
  mutation regenerateApiAccessKey($organisationId: ID!) {
    data: regenerateApiAccessKey(organisationId: $organisationId)
  }
`;

const regenerateApiAccessKey = async ({ client, params }) => {
  try {
    const response = await client.mutate({
      mutation: RegenerateApiAccessKeyMutation,
      variables: params,
    });
    return response.data;
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  }
};

export default regenerateApiAccessKey;
