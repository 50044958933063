import React, { Component } from 'react';
import { FormSection } from 'redux-form';
import { withStyles, Grid, Card, CardContent } from '@material-ui/core';
import {
  Edit,
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  AutocompleteInput,
  TextInput,
  SaveButton,
  Toolbar,
  required,
} from 'react-admin';
import { ShoppingCart as icon } from '@material-ui/icons';
import countryCodes from './localeCodes';
import { isValidUrl } from 'util/validation';

const validate = {
  url: input => (input ? isValidUrl(input) : 'Valid URL Required'),
};

const DeviceDefaultsForm = ({ deviceName }) => {
  return (
    <Grid item md={6}>
      <Card>
        <CardContent>
          <TextInput
            source="default"
            label={`${deviceName} Fallback Storefront URL`}
            fullWidth
            required
            type="url"
            validate={[validate.url]}
          />
          <ArrayInput label="International Storefronts" source="international">
            <SimpleFormIterator key="country_stores">
              <AutocompleteInput
                source="country"
                label="Country"
                validate={[required()]}
                choices={countryCodes}
              />
              <TextInput
                label="Storefront URL"
                source="url"
                required
                type="url"
                validate={[validate.url]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </CardContent>
      </Card>
    </Grid>
  );
};

const StorefrontToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const styles = {
  container: {
    // For some reason this is getting overridden when build
    width: 'calc(100% + 24px)',
  },
};

class Storefronts extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Edit {...this.props} title="Storefronts" id={'default'}>
        <SimpleForm toolbar={<StorefrontToolbar />} redirects={'/'}>
          <Grid
            className={classes.container}
            container
            spacing={
              // This is pre-version 4 Material-UI API. Version 4 uses spacing
              // values between 1-10.
              24
            }
            justify="space-between"
          >
            <FormSection name="ios">
              <DeviceDefaultsForm deviceName="iOS" />
            </FormSection>

            <FormSection name="android">
              <DeviceDefaultsForm deviceName="Android" />
            </FormSection>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}

export default {
  list: withStyles(styles)(Storefronts),
  icon,
};
