import gql from 'graphql-tag';
import { runQuery } from 'util/graphqlHelper';

const getStorefrontDefaultsData = ({ client, params = {} }) => {
  return runQuery({
    client,
    fetchPolicy: 'network-only',
    query: gql`
      query GetStorefrontDefaults {
        StorefrontDefaults {
          id
          ios {
            default
            international {
              id
              country
              url
            }
          }
          android {
            default
            international {
              id
              country
              url
            }
          }
        }
      }
    `,
  }).then(result => {
    return {
      data: result.data.StorefrontDefaults,
    };
  });
};

export default getStorefrontDefaultsData;
