import React from 'react';
import * as PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { addField, Labeled } from 'react-admin';
import DurationInputMask from 'react-duration-input-mask';

const DurationInput = props => {
  return (
    <Labeled label={props.label}>
      <DurationInputMask
        component={TextField}
        defaultValue={props.defaultValue}
        value={props.input.value}
        handleBlur={value => props.input.onChange(value)}
      />
    </Labeled>
  );
};

DurationInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.number,

  // These are from the field.
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
};

DurationInput.defaultProps = {
  label: 'Duration',
  defaultValue: 0,
};

export default addField(DurationInput);
